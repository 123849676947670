<template>
  <Market />
</template>
<script>
import Market from '@/components/market'
export default {
  components: {
    Market
  }
}
</script>
