<template>
  <!-- wallets/资金 -->
  <div class="otherCard-wrap padding15">
    <div class="top-box" v-if="loginStatus">
      <div class="card-box-wrap ub ub-btw">
        <div class="otherCard-card-box">
          <div class="card-header ub ub-btw font-weight400">
            <div class="ub left-box ub-h-c">
              <div class="font12 color-fff">
                {{ $t('wallets.totalBalance')}}
              </div>
              <div class="font10 color-fff text-c ub ub-cen">
                <p class="color-fff">(USDT)</p>
              </div>
              <div class="ub ub-cen" @click="clickEyeFn">
                <img  src="@/assets/images/walltes/eye.svg" alt="" srcset="">
              </div>
            </div>
          </div>
          <div class="amount-box">
            <p class="font24 font-weight700 color-fff">{{ eyeStatus === 'open' ? assets.amount : '*****'}}</p>
            <p class="main-text-3 font12">≈ ${{ eyeStatus === 'open' ? assets.amount : '*****'}}</p>
          </div>
        </div>
        <div class="right-deposit-box ub ub-cen font16 padding5 btn-color bg-fff" @click="goDeposit">
          {{ $t('deposit.deposit') }}
        </div>
      </div>
      <!-- kyc验证 -->
      <!-- <div class="otherCard-card-box ub ub-btw">
        <div class="verify ub ub-btw">
          <span class="font16">A message prompts the user to Verify Identity</span>
          <div class="box-button ub ub-btw">
            Verify
            <img src="@/assets/images/market/otherCard/line-r-array.svg" />
          </div>
        </div>
        <div class="avatar"></div>
      </div> -->
      <div class="ub ub-btw handler-box margin-top16 font14">
        <div class="button-box" @click="goTargetPage(item.routerPath, item.query)" v-for="(item, index) in tabList" :key="index">
          <div class="ub ub-cen otherCard-icon-box">
            <img :src="item.iconPath" alt="" srcset="">
          </div>
          <p class="text-c color-fff font12" style="margin-top: 6px;">
            {{ $t(item.title) }}
          </p>
        </div>
      </div>
    </div>
    <div class="ub ub-btw otherCard-no-login" v-if="!loginStatus">
      <div class="left-box">
        <p class="font16 color-fff  font-weight700">{{ $t('auth.login') }} / {{ $t('auth.signup') }}</p>
        <p class="font12 margin-top8 margin-bottom8 main-text-3">
          {{ $t('auth.homtTip') }}
        </p>
        <div @click="goSignUp" class="left-signup-box margin-top16 ub ub-cen font12 padding5 btn-color bg-fff">
          {{ $t('auth.signup') }} <img src="@/assets/images/market/otherCard/rightArrow.svg" alt="">
        </div>
      </div>
      <div class="right-box">
        <img src="@/assets/images/market/otherCard/noLogin.svg" alt="">
      </div>
    </div>
  </div>
</template>
<script>
import { getToken } from '@/utils/token.js'
import { fundBalance } from '@/api/assetsCenter.js'
import Vue from 'vue'
import { Tab, Tabs } from 'vant'
Vue.use(Tab).use(Tabs)
const buySvg = require('@/assets/images/market/otherCard/buy.svg')
const depositSvg = require('@/assets/images/market/otherCard/deposit.svg')
const withdrawSvg = require('@/assets/images/market/otherCard/withdraw.svg')
const securitySvg = require('@/assets/images/market/otherCard/security.svg')
const helpSvg = require('@/assets/images/market/otherCard/help.svg')

export default {
  components: {
  },
  data () {
    return {
      active: 0,
      assets: {},
      eyeStatus: 'open',
      tabList: [
        {
          title: 'wallets.buy',
          iconPath: buySvg,
          routerPath: '/c2c/buyCoin'
        },
        {
          title: 'wallets.deposit',
          iconPath: depositSvg,
          routerPath: '/depositInfo',
          query: {
            coinName: 'TetherUS',
            label: 'USDT'
          }
        },
        {
          title: 'wallets.withdraw',
          iconPath: withdrawSvg,
          routerPath: '/networkWithdraw',
          query: {
            coinName: 'TetherUS',
            label: 'USDT'
          }
        },
        {
          title: 'profile.security',
          iconPath: securitySvg,
          routerPath: '/profile/security'
        },
        {
          title: 'profile.help',
          iconPath: helpSvg,
          routerPath: ''
        }
      ]
    }
  },
  computed: {
    loginStatus () {
      return getToken()
      // return false
    }
  },
  mounted () {
    if (this.loginStatus) {
      this.fundBalanceFn()
    }
  },
  methods: {
    goTargetPage (path, query) {
      if (!path) {
        return false
      }
      this.$router.push({
        path: path,
        query: query
      })
    },
    clickEyeFn () {
      const statusObj = {
        open: 'close',
        close: 'open'
      }
      this.eyeStatus = statusObj[this.eyeStatus]
    },
    goSignUp () {
      sessionStorage.setItem('Authenticated', false)
      this.$router.push('/register/login')
    },
    goHistory () {
      this.$router.push({
        path: '/history/walltes/deposit'
      })
    },
    fundBalanceFn () {
      fundBalance().then(res => {
        console.log(res, 'res')
        this.assets = res.data
        this.$store.dispatch('updateTotalAssets', this.assets)
        this.$toast.clear()
      })
    },
    goDeposit () {
      this.$router.push({
        path: '/depositInfo',
        query: {
          coinName: 'TetherUS',
          label: 'USDT'
        }
      })
      // this.$router.push({
      //   path: '/deposit'
      // })
    },
    goWithdraw () {
      this.$router.push({
        path: '/networkWithdraw',
        query: {
          coinName: 'TetherUS',
          label: 'USDT'
        }
      })
      // this.$router.push({
      //   path: '/withdraw'
      // })
    }
  }
}
</script>

<style lang="less">
.otherCard-wrap{
  box-sizing: border-box;
  min-height: 300px;
  background-color: rgba(0, 0, 0, 0);
  .color-fff{
    color: #fff;
  }
  .btn-color{
    color: #5B21CB;
  }
  .bg-fff{
    background-color: #fff;
  }
  .otherCard-no-login{
    .left-box{
      height: 240px;
      width: calc(100% - 240px);
      .left-signup-box{
        width: 45%;
        height: 48px;
        border-radius: 8px;
        background: #FFF;
      }
    }
    .right-box{
      width: 240px;
      height: 240px;
    }
  }
  .card-box-wrap {
    height: 160px;
    .right-deposit-box{
      width: 176px;
      height: 56px;
      border-radius: 10px;
    }
  }
  .top-box{
    // background-size: cover;
    background-position: 0px -80px;
  }
  .otherCard-card-box{
    .left-box{
      justify-content: flex-start;
      align-items: center;
    }
  }
  .handler-box{
    .button-box{
      box-sizing: border-box;
      .otherCard-icon-box{
        margin: 0 auto;
        width: 80px;
        height: 80px;
        background-color: #fff;
        border-radius: 50%;
      }
    }
  }
  .wallets-tab-wrap{
    .van-tab{
      flex:none;
      margin-right: 48px;
    }
    .van-tabs__line{
      background-color: var(--line-gradient-start-color);
  }
  }
  .verify {
    flex: 1;
    flex-direction: column;
    padding-right: 48px;
    height: 192px;
    color: #FFF;
    font-family: Roboto;
    font-style: normal;
    font-weight: 700;
    box-sizing: border-box;
    .box-button {
      padding: 6px 6px 6px 16px;
      align-self: start;
      width: 128px;
      height: 48px;
      border-radius: 4px;
      background: #FFF;
      color: var(--primary-color);
      font-family: Roboto;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      box-sizing: border-box;
    }
  }
  .avatar {
    width: 160px;
    height: 160px;
    background: rgba(255, 255, 255, 0.10);
  }
}
</style>
