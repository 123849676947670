<template>
  <div class="market-wrapper">
    <div class="padding-left15 padding-right15 padding-top15 market-search-wrap" @click="goSearch">
      <van-search
        shape="round"
        :disabled="true"
        :placeholder="$t('nav.search')"
      />
      <div class="search-mask-box" @click="goSearch"></div>
    </div>
    <OtherCard></OtherCard>
    <!-- search 跳转到搜索页面 不过滤当前页面的信息 BN逻辑 -->
    <div class="list-box-wrap ">
      <van-tabs v-model="tabActive" background="var(--background-color-4)" title-active-color="var(--main-text-1)">
      <van-tab
        v-for="tab in tabs"
        :key="tab.value"
        :title="$t(`market.${tab.label}`)"
      >
        <div class="component-wrapper padding15 proHeight">
          <component :is="tab.components" :ref="tab.components"></component>
        </div>
      </van-tab>
    </van-tabs>
    </div>
  </div>
</template>
<script>
import OtherCard from '@/components/market/otherCard/index.vue'
import All from '@/components/market/all/index.vue'
import Favorites from '@/components/market/favorites/index.vue'

export default {
  components: {
    All,
    Favorites,
    OtherCard
  },
  watch: {
    tabActive () {
      this.$nextTick(() => {
        this.clearChaildEvent()
        this.emitChaildEvent()
      })
    }
  },
  data () {
    return {
      tabActive: 1,
      empty: false,
      tabs: [
        { label: 'favorites', value: 1, components: 'Favorites' },
        { label: 'all', value: 2, components: 'All' }
      ]
    }
  },
  computed: {
    isDev () {
      return process.env.NODE_ENV === 'development'
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.emitChaildEvent()
    })
  },
  beforeDestroy () {
    this.clear()
  },
  methods: {
    clear () {
      if (this.$refs.All) {
        this.$refs.All[0].cleaeEvent()
      }
      if (this.$refs.Favorites) {
        this.$refs.Favorites[0].cleaeEvent()
      }
    },
    goSearch () {
      this.$router.push({
        path: '/search'
      })
    },
    clearChaildEvent () {
      const handle = {
        Favorites: () => {
          this.$refs.All[0].cleaeEvent()
        },
        All: () => {
          this.$refs.Favorites[0].cleaeEvent()
        }
      }
      const tabActive = this.tabs[this.tabActive].components
      handle[tabActive]()
    },
    emitChaildEvent () {
      const handle = {
        Favorites: () => {
          this.$refs.Favorites[0].emitEvent()
        },
        All: () => {
          this.$refs.All[0].emitEvent()
        }
      }
      const tabActive = this.tabs[this.tabActive].components
      handle[tabActive]()
    }
  }
}
</script>
<style lang="less">
@import "~@/assets/styles/mixins.less";
.market-wrapper {
  height: 100%;
  background-color: #5B21CB;
  .list-box-wrap{
    padding-top: 30px;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    background-color: #fff;
  }
  .market-search-wrap{
    position: relative;
    .search-mask-box{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0);
    }
  }
  .component-wrapper {
    border-top: 1px solid var(--divider-color);
    position: relative;
    // height: calc(100vh - 259px - 148px) 116px 98px 104px 36
  }
  .devHeight {
    height: calc(100vh - 259px)
  }
  .proHeight {
    height: calc(100vh - 259px)
  }
  .empty {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .van-search {
    padding: 0;
    margin: 0;
    border-radius: 30px;
    background-color: rgba(255,255,255,0.2);
    .van-cell {
      background-color: rgba(255,255,255,0);
    }
    .van-search__content {
      background-color: rgba(255,255,255,0);
    }
  }
  .van-tab {
    flex: none;
    margin-right: 48px;
    padding-bottom: 1.06667vw;
    font-weight: 500;
    font-size: 28px;
    color: var(--main-text-2);
    &--active {
      .border-1px(var(--primary-color), solid, 12px);
      color: var(--main-text-1);
      &::after {
        top: -1px;
      }
    }
  }
  .van-tabs {
    &__wrap {
      margin: 0 30px;
      height: 104px;
      background-color: var(--input-bg);
    }
    &__nav {
      background-color: var(--input-bg);
    }
    &__line {
      background-color: transparent;
    }
    &__nav--line {
      padding: 0;
    }
  }
}
</style>
